<template>
  <v-form
    :ref="defaultKey"
    :id="useId"
    v-bind="vBtnAttr"
    v-model="getValid"
    lazy-validation
    @submit.prevent="onSubmit"
    @forceRefresh="_forceRefresh"
    :key="refresh"
  >
    <component
      v-for="(item, index) in slotComponents"
      :is="item.component"
      :key="index"
      :propsValue="deconstruct(item)"
      :isSubmit="isSubmit"
      :props="commonProps"
      :propsFormKey="defaultKey"
      :auth="auth"
      :ref="item.name"
      class="mb-1"
    ></component>
    <slot name="action" v-bind:defaultKey="defaultKey"></slot>
  </v-form>
</template>

<script lang="babel" type="text/babel">
import customInstance from "@/store/modules/customFormInstance.js";

export default {
  props: {
    auth: {
      type: Boolean,
    },
    slotComponents: Array,
    handlerSubmit: Function,
    value: [Object, Array],
    useIdProps: String,
    commonProps: Object,
  },
  data: () => ({
    // valid: true,
    hasInit: false,
    isSubmit: false,
    refresh: 0,
    vBtnAttr: {},
  }),
  mounted() {
    this.$store.dispatch(
      `customForm/${this.defaultKey}/setForm`,
      this.$refs[this.defaultKey]
    );
    this.$emit("update:getFormKey", this.defaultKey);
    this.$emit("update:getForm", this);
    this.$emit("update:getValidate", this.valid);
  },
  created() {
    this.__init();
    this._initVuexModule();
    this.getControl.validate = this.validate;
  },
  beforeDestroy() {
    this._destroyVuexModule();
  },
  computed: {
    getControl() {
      return this.$store.getters[`customForm/${this.defaultKey}/getControl`];
    },
    getValid: {
      get() {
        return this.$store.getters[`customForm/${this.defaultKey}/getValid`];
      },
      set(value) {
        this.$store.dispatch(`customForm/${this.defaultKey}/setValid`, value);
      },
    },
    useId() {
      return this.useIdProps ? this.useIdProps : this.defaultKey;
    },
    defaultKey() {
      return (
        "uuid_" +
        `${Math.floor(Math.random() * 100000)}` +
        "_" +
        `${Math.floor(Math.random() * 1000)}`
      );
    },
  },

  methods: {
    _initVuexModule() {
      this.hasInit = false;
      this.$store.registerModule(
        ["customForm", this.defaultKey],
        customInstance()
      );
      this.hasInit = true;
    },
    _hasModule() {
      return (
        this.$store.getters[`customForm/${this.defaultKey}/getForm`] !=
        undefined
      );
    },
    _destroyVuexModule() {
      if (this._hasModule() === false) return;
      this.$store.unregisterModule(["customForm", this.defaultKey]);
    },
    customInstance() {
      return customInstance;
    },
    __init() {
      this.vBtnAttr = { ...this.$attr };
    },
    deconstruct(item) {
      if (!this.value) return;

      return this.chooseType(item);
    },
    chooseType(item) {
      if (Array.isArray(this.value)) {
        return this.value.map((obj, index) => {
          return this.getValues(item, obj);
        });
      }
      return this.getValues(item, this.value);
    },
    getValues(item, value) {
      let setValueArray = [];

      if (item.values.includes("*")) {
        setValueArray = Object.keys(value);

        if (item.excepts) {
          item.excepts.forEach((ele) => {
            let index = setValueArray.indexOf(ele);
            setValueArray.splice(index, 1);
          });
        }
      } else {
        setValueArray = item.values;
      }
      return this.setValues(value, setValueArray);
    },
    setValues(data, values) {
      return values.reduce((acc, cur) => {
        acc[cur] = data[cur];
        return acc;
      }, {});
    },
    async onSubmit() {
      // await  this.setSubmit()

      const findInputs = await this.findInput();
      let validate = await this.validate();
      // 驗證通過
      if (validate) await this.handlerSubmit(findInputs);

      // await  this.setSubmit()
    },
    _forceRefresh() {
      this.refresh = Math.random();
    },
    validate() {
      return this.$refs[this.defaultKey].validate();
    },
    setSubmit() {
      this.isSubmit = !this.isSubmit;
      return this.isSubmit;
    },
    async findInput() {
      await this.$nextTick();
      const formKey = this.defaultKey;

      let arr_ele = {};
      // form表單內有name屬性的dom
      for (let i = 0; i < this.$refs[formKey].$el.elements.length; i++) {
        let ele = this.$refs[formKey].$el[i];

        let value = this.setValue(ele);
        if (value && ele.name) {
          switch (ele.type) {
            case "hidden":
            case "submit":
            case "textarea":
            case "text":
              arr_ele[ele.name] = value;
              break;

            default:
              break;
          }
        }
      }

      return arr_ele;
    },
    setValue(ele) {
      let value = null;
      switch (ele.dataset.type) {
        case "photo":
          value = JSON.parse(ele.value);
          break;

        default:
          value = ele.value;
          break;
      }
      return value;
    },
  },
};
</script>
